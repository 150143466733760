export const SERVER_ERROR = 104;
export const INVALID_PARAMS = 105;
export const EMAIL_ALREADY_USED = 108;
export const UNAUTHORIZED = 109;
export const EMAIL_IS_INVALID = 201;
export const FILL_PARAMS_ERROR = 202;

export const ERROR_MESSAGES = {
    [String(SERVER_ERROR)]: 'Ошибка сервера',
    [String(EMAIL_ALREADY_USED)]: 'Почта уже используется',
    [String(INVALID_PARAMS)]: 'Невалидные данные',
    [String(UNAUTHORIZED)]: 'Неверный логин или пароль',
    [String(EMAIL_IS_INVALID)]: 'Невалидная почта',
    [String(FILL_PARAMS_ERROR)]: 'Не заполнены все обязательные поля'
}

export function getErrorMessage(errorCode: number): string | null {
    if (!errorCode) {
        return null;
    }
    return ERROR_MESSAGES[String(errorCode)];
}