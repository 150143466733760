import React from 'react';
import { connect } from 'react-redux';
import { getEventLog } from '../Actions/log';
import EventLogView from '../Components/Log/event';
import { EventLog } from '../Entities/EventLog';

interface IProps {
    isLoading: boolean;
    error: string;
    page: number;
    pages: number;
    list: EventLog[];
    getList: (page?: number) => void;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class EventLogPage extends React.Component<IProps, IState> {

    async componentDidMount() {
        await this.props.getList();
    }

    onLoadMore = (page: number) => {
        console.log(page);
        this.props.getList(page);
    }

    render() {
        const { error, isLoading, page, pages, list } = this.props;
        return (
            <EventLogView
                error={error}
                isLoading={isLoading}
                list={list}
                page={page}
                pages={pages}
                onLoadMore={this.onLoadMore}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getList: (page: number = 1) => dispatch(getEventLog(page)),
    };
}

const mapStateToProps = (state: any) => {
    return {
        page: state.log.eventPage,
        pages: state.log.eventPages,
        isLoading: state.log.isLoading,
        list: state.log.eventList,
        error: state.log.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(EventLogPage);
