import React from 'react';
import { connect } from 'react-redux';
import { getList, remove } from '../Actions/organization';
import ListOrganization from '../Components/Organization/List';
import Routes from '../Shared/routes';
import Organization from '../Entities/Organization';

interface IProps {
    isLoading: boolean;
    error: string;
    list: Organization[];
    getOrganization: () => void;
    remove: (nId: number) => void;
    history: any;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class OrganizationListPage extends React.Component<IProps, IState> {

    async componentDidMount() {
        if (!this.props.list.length) {
            await this.props.getOrganization();
        }
        else {
            this.forceUpdate();
        }
    }

    onRemove = async (nId: number) => {
        await this.props.remove(nId);
        this.props.history.goBack();
    }

    onAdd = () => {
        this.props.history.push(Routes.ORGANIZATION_CREATE);
    }

    render() {
        const { error, isLoading, list} = this.props;
        return (
            <ListOrganization
                error={error}
                isLoading={isLoading}
                list={list}
                add={this.onAdd}
                remove={this.onRemove}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getOrganization: () => dispatch(getList()),
        remove: (nId: number) => dispatch(remove(nId))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.organization.isLoading,
        list: state.organization.list,
        error: state.organization.error,
        currentPage: state.organization.currentPage,
        pages: state.organization.pages
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(OrganizationListPage);
