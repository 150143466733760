import Api from '.';
import { IRequestResponse } from './index';
import { IOrganizationEdit } from '../Shared/interfaces';

class Employee extends Api {
    ENDPOINT = 'employees';

    create(data: IOrganizationEdit) {
        return this.post(this.ENDPOINT, data);
    }

    edit(id: number, data: IOrganizationEdit) {
        return this.put(`${this.ENDPOINT}/${id}`, data);
    }

    remove(id: number) {
        return this.delete(`${this.ENDPOINT}/${id}`);
    }

    getList(): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}`);
    }

    getOne(emplId: number): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}/${emplId}`);
    }

    getListByChannel(channelId: number): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}`, { channelId });
    }

    linkDepartments(employeeId: number, add: number[], remove: number[]): Promise<IRequestResponse> {
        return this.patch(`${this.ENDPOINT}/${employeeId}/departments`, { add, remove });
    }

}

const employee = new Employee();

export default employee;
