import React from 'react';
import { connect } from 'react-redux';
import { edit, getList } from '../Actions/organization';
import EditOrganization from '../Components/Organization/Item';
import Routes from '../Shared/routes';
import Organization from '../Entities/Organization';
import { FILL_PARAMS_ERROR } from '../Shared/errors';
import { IOrganizationEdit } from '../Shared/interfaces';

interface IProps {
    isLoading: boolean;
    error: number;
    list: Organization[];
    edit: (id: number, data: IOrganizationEdit) => void;
    getOrganizations: () => void;
    match: any;
    history: any;
}

interface IState {
    error: number | null;
}

class EditOranizationPage extends React.Component<IProps, IState> {
    organization: Organization | undefined;

    state = {
        error: null
    }

    async componentDidMount() {
        if (!this.props.list.length) {
            await this.props.getOrganizations();
        }
        const id = this.props.match.params.organizationId;
        this.organization = this.props.list.find((n: Organization) => n.getId() == id);
        if (!this.organization) {
            this.props.history.push(Routes.ORGANIZATION_LIST);
        }
        else {
            this.forceUpdate();
        }
    }

    onSubmit = async (data: IOrganizationEdit) => {
        if (this.organization) {
            if (!data.name) {
                this.setState({
                    error: FILL_PARAMS_ERROR
                })
            }
            await this.props.edit(this.organization.getId(), data);
            if (!this.props.error) {
                this.props.history.push(Routes.ORGANIZATION_LIST);
            }
        }
    }

    render() {
        return (
            <EditOrganization
                error={this.props.error || this.props.error}
                isLoading={this.props.isLoading}
                organization={this.organization}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        edit: (id: number, data: IOrganizationEdit) => dispatch(edit(id, data)),
        getOrganizations: () => dispatch(getList())
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.organization.isLoading,
        list: state.organization.list,
        error: state.organization.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(EditOranizationPage);
