import React from 'react';
import { connect } from 'react-redux';
import { getList, remove } from '../Actions/employee';
import ListEmployee from '../Components/Employee/List';
import Routes from '../Shared/routes';
import Employee from '../Entities/Employee';

interface IProps {
    isLoading: boolean;
    error: string;
    list: Employee[];
    getList: () => void;
    remove: (nId: number) => void;
    history: any;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class EmployeeListPage extends React.Component<IProps, IState> {

    async componentDidMount() {
        if (!this.props.list.length) {
            await this.props.getList();
        }
        else {
            this.forceUpdate();
        }
    }

    onRemove = async (nId: number) => {
        await this.props.remove(nId);
        this.props.history.goBack();
    }

    onAdd = () => {
        this.props.history.push(Routes.EMPLOYEE_CREATE);
    }

    render() {
        const { error, isLoading, list} = this.props;
        return (
            <ListEmployee
                error={error}
                isLoading={isLoading}
                list={list}
                add={this.onAdd}
                remove={this.onRemove}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getList: () => dispatch(getList()),
        remove: (nId: number) => dispatch(remove(nId))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.employee.isLoading,
        list: state.employee.list,
        error: state.employee.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(EmployeeListPage);
