import EmployeeAPI from '../Api/employee';
import Employee from '../Entities/Employee';
import { IEmployeeEdit } from '../Shared/interfaces';
import { mapEmployee } from '../Shared/mappers';

export const SET_ERROR = 'SET_EMPLOYEE_ERROR';
export const SET_LIST = 'SET_EMPLOYEE_LIST';
export const SET_ITEM = 'SET_EMPLOYEE_ITEM';
export const SET_LOADING = 'SET_EMPLOYEE_LOADING';

export function create(data: IEmployeeEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await EmployeeAPI.create(data);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList())
    }
}

export function edit(id: number, data: IEmployeeEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await EmployeeAPI.edit(id, data);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList())

    }
}

export function getList() {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await EmployeeAPI.getList();
        if (result) {
            return dispatch({
                type: SET_LIST,
                data: mapEmployee(data)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function getOne(id: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await EmployeeAPI.getOne(id);
        if (result) {
            return dispatch({
                type: SET_ITEM,
                data: new Employee({
                    id: data.id,
                    name: data.name,
                    email: data.email,
                    isBlock: data.isBlock,
                    isVerified: data.isVerified,
                    channels: data.channels,
                    departments: data.departments
                })
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}


export function getListByChannel(channelId: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await EmployeeAPI.getListByChannel(channelId);

        if (result) {
            return dispatch({
                type: SET_LIST,
                data: mapEmployee(data)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function linkDepartments(employeeId: number, add: number[], remove: number[]) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await EmployeeAPI.linkDepartments(employeeId, add, remove);
        if (result) {
            return dispatch(getList())
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}


export function remove(id: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await EmployeeAPI.remove(id);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList());
    }
}