import React from 'react';
import { connect } from 'react-redux';
import { getListByChannel as getDepartments, remove } from '../Actions/department';
import { getList as getChannels } from '../Actions/channel';
import ListDepartment from '../Components/Department/List';
import Routes from '../Shared/routes';
import Department from '../Entities/Department';
import Channel from '../Entities/Channel';

interface IProps {
    isDepartmentLoading: boolean;
    isChannelLoading: boolean;
    error: string;
    departmentList: Department[];
    channelList: Channel[];
    getDepartments: (channelId: number) => void;
    getChannels: () => void;
    remove: (nId: number) => void;
    history: any;
    match: any;
}


class DepartmentListPage extends React.Component<IProps> {
    channel: Channel | undefined;


    async componentDidMount() {
        const id = this.props.match.params.channelId;
        await this.props.getChannels();
        this.channel = this.props.channelList.find((c: Channel) => c.getId() == id);
        await this.props.getDepartments(id);

    }

    onRemove = async (nId: number) => {
        await this.props.remove(nId);
        this.props.history.goBack();
    }

    onAdd = () => {
        this.props.history.push(`${Routes.DEPARTMENT_CREATE}${this.channel?.getId()}`);
    }

    render() {
        const { error, isChannelLoading, isDepartmentLoading, departmentList } = this.props;
        return (
            <ListDepartment
                channel={this.channel}
                error={error}
                isLoading={isChannelLoading || isDepartmentLoading}
                list={departmentList}
                add={this.onAdd}
                remove={this.onRemove}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getDepartments: (channelId: number) => dispatch(getDepartments(channelId)),
        getChannels: () => dispatch(getChannels()),
        remove: (nId: number) => dispatch(remove(nId))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isDepartmentLoading: state.department.isLoading,
        isChannelLoading: state.channel.isLoading,
        departmentList: state.department.list,
        channelList: state.channel.list,
        error: state.department.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(DepartmentListPage);
