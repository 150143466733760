import React from 'react';
import { connect } from 'react-redux';
import { edit, getList as getChannels } from '../Actions/channel';
import EditChannel from '../Components/Channel/Item';
import Routes from '../Shared/routes';
import { FILL_PARAMS_ERROR } from '../Shared/errors';
import { IChannelEdit } from '../Shared/interfaces';
import Channel from '../Entities/Channel';

interface IProps {
    isLoading: boolean;
    error: number;
    getChannels: () => void;
    channelList: Channel[];
    edit: (id: number, data: IChannelEdit) => void;
    match: any;
    history: any;
}

interface IState {
    error: number | null;
}

class EditChannelPage extends React.Component<IProps, IState> {
    channel: Channel | undefined;

    state = {
        error: null
    }

    async componentDidMount() {
        if (!this.props.channelList.length) {
            await this.props.getChannels();
        }
        const id = this.props.match.params.channelId;
        this.channel = this.props.channelList.find((n: Channel) => n.getId() == id);
        if (!this.channel) {
            this.props.history.push(Routes.CHANNEL_LIST);
        }
        else {
            this.forceUpdate();
        }
    }

    onSubmit = async (data: IChannelEdit) => {
        if (this.channel) {
            if (!data.name) {
                this.setState({
                    error: FILL_PARAMS_ERROR
                })
            }
            await this.props.edit(this.channel.getId(), data);
            if (!this.props.error) {
                this.props.history.push(Routes.CHANNEL_LIST);
            }
        }
    }

    render() {
        return (
            <EditChannel
                error={this.props.error || this.props.error}
                isLoading={this.props.isLoading}
                channel={this.channel}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        edit: (id: number, data: IChannelEdit) => dispatch(edit(id, data)),
        getChannels: () => dispatch(getChannels()),
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.channel.isLoading,
        channelList: state.channel.list,
        error: state.channel.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(EditChannelPage);
