import React from 'react';
import { connect } from 'react-redux';
import { getListByChannel } from '../Actions/employee';
import { unlinkEmployee } from '../Actions/channel';
import ListEmployee from '../Components/Employee/ListForManageChannel';
import Routes from '../Shared/routes';
import Employee from '../Entities/Employee';

interface IProps {
    isLoading: boolean;
    error: string;
    list: Employee[];
    getListByChannel: (channelId: number) => void;
    unlinkEmployee: (channelId: number, employeeId: number) => void;
    history: any;
    match: any;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class EmployeeListPage extends React.Component<IProps, IState> {
    channelId: number;

    constructor(props: IProps) {
        super(props);
        this.channelId = props.match.params.channelId;
    }

    async componentDidMount() {
        await this.props.getListByChannel(this.channelId);
    }

    onRemove = async (nId: number) => {
        await this.props.unlinkEmployee(this.channelId, nId);
        await this.props.getListByChannel(this.channelId);
    }

    onAdd = () => {
        this.props.history.push(`${Routes.CHANNEL_LINK_EMPLOYEE}${this.channelId}`);
    }

    render() {
        const { error, isLoading, list } = this.props;
        return (
            <ListEmployee
                error={error}
                isLoading={isLoading}
                list={list}
                channelId={this.channelId}
                add={this.onAdd}
                remove={this.onRemove}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getListByChannel: (channelId: number) => dispatch(getListByChannel(channelId)),
        unlinkEmployee: (cId: number, eId: number) => dispatch(unlinkEmployee(cId, eId))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.employee.isLoading,
        list: state.employee.list,
        error: state.employee.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(EmployeeListPage);
