import React from 'react';
import { connect } from 'react-redux';
import { edit, getList as getDepartments } from '../Actions/department';
import EditDepartment from '../Components/Department/Item';
import Routes from '../Shared/routes';
import { EMAIL_IS_INVALID, FILL_PARAMS_ERROR } from '../Shared/errors';
import { IDepartmentEdit } from '../Shared/interfaces';
import Department from '../Entities/Department';

interface IProps {
    isLoading: boolean;
    error: number;
    getDepartments: () => void;
    departmentList: Department[];
    edit: (id: number, data: IDepartmentEdit) => void;
    match: any;
    history: any;
}

interface IState {
    error: number | null;
}

class EditDepartmentPage extends React.Component<IProps, IState> {
    department: Department | undefined;
    channelId: number;

    constructor(props: IProps) {
        super(props);
        this.channelId = Number(props.match.params.channelId);
        this.state = {
            error: null
        }
    }

    async componentDidMount() {
        if (!this.props.departmentList.length) {
            await this.props.getDepartments();
        }
        const id = Number(this.props.match.params.departmentId);
        this.department = this.props.departmentList.find((n: Department) => n.getId() == id);
        if (!this.department) {
            this.goToList();
        }
        else {
            this.forceUpdate();
        }
    }

    goToList = () => {
        this.props.history.push(`${Routes.DEPARTMENT_LIST}${this.channelId}`);
    }

    onSubmit = async (data: IDepartmentEdit) => {
        if (this.department) {
            if (!data.name) {
                this.setState({
                    error: FILL_PARAMS_ERROR
                });
            }
            await this.props.edit(this.department.getId(), data);
            if (!this.props.error) {
                this.goToList();
            }
        }
    }

    render() {
        return (
            <EditDepartment
                error={this.props.error || this.props.error}
                isLoading={this.props.isLoading}
                department={this.department}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        edit: (id: number, data: IDepartmentEdit) => dispatch(edit(id, data)),
        getDepartments: () => dispatch(getDepartments()),
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.department.isLoading,
        departmentList: state.department.list,
        error: state.department.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(EditDepartmentPage);
