import ChannelAPI from '../Api/channel';
import { EMPLOYEE_ROLES } from '../Shared/const';
import { IChannelEdit } from '../Shared/interfaces';
import { mapChannel } from '../Shared/mappers';

export const SET_ERROR = 'SET_CHANNEL_ERROR';
export const SET_LIST = 'SET_CHANNEL_LIST';
export const SET_LOADING = 'SET_CHANNEL_LOADING';

export function create(data: IChannelEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await ChannelAPI.create(data);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList())
    }
}

export function edit(id: number, data: IChannelEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await ChannelAPI.edit(id, data);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList())
    }
}

export function linkEmployee(channelId: number, employeeId: number, role: EMPLOYEE_ROLES) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await ChannelAPI.linkEmployee(channelId, employeeId, role);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList())
    }
}

export function unlinkEmployee(channelId: number, employeeId: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await ChannelAPI.unlinkEmployee(channelId, employeeId);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList())
    }
}


export function getList() {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await ChannelAPI.getList();
        if (result) {
            return dispatch({
                type: SET_LIST,
                data: mapChannel(data)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function remove(id: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await ChannelAPI.remove(id);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList());
    }
}