import React from 'react';
import Loading from '../Loading';
import { ERROR_MESSAGES } from '../../Shared/errors';
import Employee from '../../Entities/Employee';
import { IEmployeeEdit } from '../../Shared/interfaces';
import { EMPLOYEE_ROLES } from '../../Shared/const';
import { removeEmptyObjectValues } from '../../Shared/utils';

interface IState {
    name: string;
    email: string;
    password: string;
    isBlock: boolean;
}

interface IProps {
    employee?: Employee;
    error: number | null;
    onSubmit: (data: IEmployeeEdit) => void;
    isLoading: boolean;
}

export default class ItemEmployee extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const employee: Employee | undefined = props.employee;
        let name = '', email = '', isBlock = false;
        if (employee) {
            name = employee.name;
            email = employee.email;
            isBlock = employee.isBlock;
        }
        this.state = {
            name,
            email,
            password: '',
            isBlock
        }
    }

    componentDidUpdate(oldProps: IProps) {
        if (this.props.employee && !oldProps.employee) {
            const employee: Employee | undefined = this.props.employee;
            this.setState({
                name: employee.name,
                email: employee.email,
                isBlock: employee.isBlock
            });
        }
    }

    handleChangeName = (event: any) => {
        const target = event.target;
        this.setState({
            name: target.value
        });
    }

    handleChangeEmail = (event: any) => {
        const target = event.target;
        this.setState({
            email: target.value
        });
    }

    handleChangePassword = (event: any) => {
        const target = event.target;
        this.setState({
            password: target.value
        });
    }

    handleChangeBlock = (event: any) => {
        this.setState({
            isBlock: !this.state.isBlock
        });
    }

    renderChannel = (channel: any) => {
        return (
            <tr>
                <td>{channel.name}</td>
                <td>{channel.role == EMPLOYEE_ROLES.MANAGER ? 'Менеджер' : 'Оператор'}</td>
            </tr>
        )
    }

    renderDepartment = (department: any) => {
        return (
            <tr>
                <td>{department.name}</td>
            </tr>
        )
    }

    onSubmit = () => {
        this.props.onSubmit(removeEmptyObjectValues(this.state));
    }

    render() {
        const { isLoading, error } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (<div className="row">
                <div className="col-md-12">
                    <div className="tile">
                        <div className="row">
                            <div className="col-lg-6">
                                {error ?
                                    <p>{ERROR_MESSAGES[error]}</p> : null}
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Имя</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.name}
                                            id="exampleInputEmail1"
                                            type="text"
                                            onChange={this.handleChangeName}
                                            placeholder="Введите название" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Почта</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.email}
                                            id="exampleInputEmail1"
                                            type="text"
                                            onChange={this.handleChangeEmail}
                                            placeholder="Введите почту" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Пароль</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.password}
                                            id="exampleInputEmail1"
                                            type="password"
                                            onChange={this.handleChangePassword}
                                            placeholder="Введите пароль" />
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input
                                                onChange={this.handleChangeBlock}
                                                checked={this.state.isBlock} className="form-check-input" type="checkbox" />Заблокирован
                                        </label>
                                    </div>
                                </form>
                                <div className="tile-footer">
                                    <button
                                        onClick={this.onSubmit}
                                        className="btn btn-primary"
                                        type="submit">Сохранить</button>
                                </div>
                                <br />
                                <table className="table table-striped">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Каналы</label>
                                    </div>
                                    <tbody>
                                        {this.props.employee?.channels.map(this.renderChannel)}
                                    </tbody>
                                </table>
                                <table className="table table-striped">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Отделы</label>
                                    </div>
                                    <tbody>
                                        {this.props.employee?.departments.map(this.renderDepartment)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }
}