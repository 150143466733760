import React from 'react';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import Routes from '../../Shared/routes';
import Channel from '../../Entities/Channel';
import { ErrorLog } from '../../Entities/ErrorLog';
import Pagination from '../Pagination';


interface IProps {
	list: ErrorLog[];
	error: string;
	page: number;
	pages: number;
	isLoading: boolean;
	onLoadMore: (page: number) => void;
}

export default class ErrorLogList extends React.Component<IProps> {

	renderItem = (item: ErrorLog) => {
		return (
			<tr>
				<td>{item.getId()}</td>
				<td>{item.service}</td>
				<td>{item.message}</td>
				<td>
					{item.getDate()}
				</td>
			</tr>
		)
	}

	render() {
		const { isLoading, list } = this.props;
		if (isLoading) {
			return <Loading />;
		}
		else {
			return (
				<>

					<div className="app-title">
						<div>
							<h1><i className="fa fa-th-list"></i> Лог ошибок</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<div className="tile">
								<table className="table table-striped">
									<thead>
										<tr>
											<th>#</th>
											<th>Сервис</th>
											<th>Ошибка</th>
											<th>Дата</th>
										</tr>
									</thead>
									<tbody>
										{list.map(this.renderItem)}
									</tbody>
								</table>
								<Pagination
									pages={this.props.pages}
									current={this.props.page}
									onPage={this.props.onLoadMore}
								/>
							</div>
						</div>
					</div>
				</>
			)
		}
	}
}