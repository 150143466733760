import Api from '.';
import { IRequestResponse } from './index';

class Log extends Api {
    ENDPOINT = 'log/';

    getEventLog(page: number = 1): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}event`, { page });
    }

    getErrorLog(page: number = 1): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}error`, { page });
    }
}

const log = new Log();

export default log;
