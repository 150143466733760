import { EMPLOYEE_ROLES } from "../Shared/const";
import { BaseEntity } from "./BaseEntity";

export interface IChannelRole {
	channelId: number;
	role: EMPLOYEE_ROLES;
}

interface IConstructor {
	id: number;
	name: string;
	email: string;
	isBlock: boolean;
	isVerified: boolean;
	channelsRoles?: any;
	channels: any[];
	departments: any[];
}

export default class Employee extends BaseEntity {
	public channelsRoles: IChannelRole[];
	public name: string;
	public email: string;
	public isBlock: boolean;
	public isVerified: boolean;
	public channels: any[];
	public departments: any[];

	constructor({id, name, email, isBlock, isVerified, channelsRoles, channels, departments}: IConstructor) {
		super(id);
		this.channelsRoles = channelsRoles;
		this.name = name;
		this.email = email;
		this.isBlock = isBlock;
		this.isVerified = isVerified;
		this.channels = channels;
		this.departments = departments;
	}
}