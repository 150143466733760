import React from 'react';
import { connect } from 'react-redux';
import { difference } from 'lodash';
import { getOne as getEmployee, linkDepartments } from '../Actions/employee';
import { linkEmployee } from '../Actions/channel';
import { getListByChannel as getDepartments } from '../Actions/department';
import LinkEmployee from '../Components/Channel/EditLinkEmployee';
import Employee from '../Entities/Employee';
import Department from '../Entities/Department';
import { ILinkEmployee } from '../Shared/interfaces';
import Loading from '../Components/Loading';
import { EMPLOYEE_ROLES } from '../Shared/const';
import Routes from '../Shared/routes';

interface IProps {
    isEmployeeLoading: boolean;
    isDepartmentLoading: boolean;
    error: number;
    employee: Employee;
    employeeList: Employee[];
    departmentList: Department[];
    getDepartments: (channelId: number) => void;
    linkEmployee: (channelId: number, employeeId: number, role: EMPLOYEE_ROLES) => void;
    linkDepartments: (employeeId: number, add: number[], remove: number[]) => void;
    getEmployee: (id: number) => void;
    history: any;
    match: any;
}

interface IState {
    isLoading: boolean;
}

class LinkEmployeePage extends React.Component<IProps, IState> {
    channelId: number;
    employeeId: number;
    role: EMPLOYEE_ROLES | undefined;
    departments: number[] = [];


    constructor(props: IProps) {
        super(props);
        this.channelId = Number(props.match.params.channelId);
        this.employeeId = Number(props.match.params.employeeId);
        this.state = {
            isLoading: true
        }
    }

    async componentDidMount() {
        await this.props.getDepartments(this.channelId);
        await this.props.getEmployee(this.employeeId);
        this.role = this.props.employee.channels.find((item: any) => item.id == this.channelId)?.role;
        this.departments = this.props.employee.departments.map((d: any) => d.id);
        this.setState({
            isLoading: false
        })
    }

    onSubmit = async (data: ILinkEmployee) => {
        await this.props.linkEmployee(this.channelId, this.employeeId, data.role);
        if (data.role == EMPLOYEE_ROLES.OPERATOR) {
            const add = difference( data.departments, this.departments);
            const remove = difference(this.departments, data.departments); 
            await this.props.linkDepartments(this.employeeId, add, remove);
        }
        this.props.history.push(`${Routes.EMPLOYEE_LIST_BY_CHANNEL}${this.channelId}`)
    }

    render() {
        const { error, isDepartmentLoading, isEmployeeLoading, departmentList, employeeList } = this.props;
        if (this.state.isLoading || isDepartmentLoading || isEmployeeLoading) {
            return <Loading />;
        }

        return (
            <LinkEmployee
                error={error}
                role={this.role}
                departments={this.departments}
                departmentList={departmentList}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getDepartments: (channelId: number) => dispatch(getDepartments(channelId)),
        getEmployee: (id: number) => dispatch(getEmployee(id)),
        linkDepartments: (employeeId: number, add: number[], remove: number[]) =>
            dispatch(linkDepartments(employeeId, add, remove)),
        linkEmployee: (channelId: number, employeeId: number, role: EMPLOYEE_ROLES) =>
            dispatch(linkEmployee(channelId, employeeId, role))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isEmployeeLoading: state.employee.isLoading,
        isDepartmentLoading: state.department.isLoading,
        employeeList: state.employee.list,
        employee: state.employee.item,
        departmentList: state.department.list,
        error: state.employee.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(LinkEmployeePage);
