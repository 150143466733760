import Api from '.';
import { IRequestResponse } from './index';
import { IOrganizationEdit } from '../Shared/interfaces';

class Organization extends Api {
    ENDPOINT = 'organizations';

    create(data: IOrganizationEdit) {
        return this.post(this.ENDPOINT, data);
    }

    edit(id: number, data: IOrganizationEdit) {
        return this.put(`${this.ENDPOINT}/${id}`, data);
    }

    remove(id: number) {
        return this.delete(`${this.ENDPOINT}/${id}`);
    }

    getList(): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}`);
    }

}

const organization = new Organization();

export default organization;
