import React from 'react';
import { connect } from 'react-redux';
import { getList as getEmployees, linkDepartments } from '../Actions/employee';
import { linkEmployee } from '../Actions/channel';
import { getListByChannel as getDepartments } from '../Actions/department';
import LinkEmployee from '../Components/Channel/LinkEmployee';
import Employee, { IChannelRole } from '../Entities/Employee';
import Department from '../Entities/Department';
import { ILinkEmployee } from '../Shared/interfaces';
import Loading from '../Components/Loading';
import { EMPLOYEE_ROLES } from '../Shared/const';
import Routes from '../Shared/routes';

interface IProps {
    isEmployeeLoading: boolean;
    isDepartmentLoading: boolean;
    error: number;
    employeeList: Employee[];
    departmentList: Department[];
    getDepartments: (channelId: number) => void;
    linkEmployee: (channelId: number, employeeId: number, role: EMPLOYEE_ROLES) => void;
    linkDepartments: (employeeId: number, add: number[], remove: number[]) => void;
    getEmployees: () => void;
    history: any;
    match: any;
}

interface IState {
    isLoading: boolean;
}

class LinkEmployeePage extends React.Component<IProps, IState> {
    channelId: number;

    constructor(props: IProps) {
        super(props);
        this.channelId = props.match.params.channelId;
        this.state = {
            isLoading: true
        }
    }

    async componentDidMount() {
        await this.props.getDepartments(this.channelId);
        await this.props.getEmployees();
        this.setState({
            isLoading: false
        })
    }

    onSubmit = async (data: ILinkEmployee) => {
        await this.props.linkEmployee(this.channelId, Number(data.employeeId), data.role);
        if (data.role == EMPLOYEE_ROLES.OPERATOR && data.employeeId) {
            await this.props.linkDepartments(data.employeeId, data.departments, []);
        }
        this.props.history.push(`${Routes.EMPLOYEE_LIST_BY_CHANNEL}${this.channelId}`)
    }

    filterEmployee = (empl: Employee) => {
        return !empl.channelsRoles.find((item: IChannelRole) => item.channelId == this.channelId);
    }

    render() {
        const { error, isDepartmentLoading, isEmployeeLoading, departmentList, employeeList } = this.props;
        if (this.state.isLoading || isDepartmentLoading || isEmployeeLoading) {
            return <Loading />;
        }
        return (
            <LinkEmployee
                error={error}
                departmentList={departmentList}
                employeeList={employeeList.filter(this.filterEmployee)}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getDepartments: (channelId: number) => dispatch(getDepartments(channelId)),
        getEmployees: () => dispatch(getEmployees()),
        linkDepartments: (employeeId: number, add: number[], remove: number[]) =>
            dispatch(linkDepartments(employeeId, add, remove)),
        linkEmployee: (channelId: number, employeeId: number, role: EMPLOYEE_ROLES) =>
            dispatch(linkEmployee(channelId, employeeId, role))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isEmployeeLoading: state.employee.isLoading,
        isDepartmentLoading: state.department.isLoading,
        employeeList: state.employee.list,
        departmentList: state.department.list,
        error: state.employee.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(LinkEmployeePage);
