import AccountAPI from '../Api/account';
import { mapAdmin } from '../Shared/mappers';
import { IAdminEdit } from '../Shared/interfaces';
import Admin from '../Entities/Admin';

export const SET_CURRENT = 'SET_CURRENT_ADMIN';
export const SET_LIST = 'SET_LIST_ADMIN';
export const SET_ERROR = 'SET_ERROR_ADMIN';
export const SET_LOADING = 'SET_LOADING_ADMIN';

export function getCurrent() {
    return async (dispatch: any) => {
        const { result, data, error } = await AccountAPI.getCurrent();
        if (result) {
            return dispatch({
                type: SET_CURRENT,
                data: new Admin(data.id, data.name, data.email)
            });
        }

    }
}

export function getList() {
    return async (dispatch: any) => {
        const { result, error, data } = await AccountAPI.getList();
        if (result) {
            return dispatch({
                type: SET_LIST,
                list: mapAdmin(data)
            });
        }
        else {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
    }
}

export function create(admin: IAdminEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });

        const { error, result } = await AccountAPI.create(admin);
        if (result) {
            return dispatch(getList());
        }
        else {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
    }
}

export function edit(id: number, admin: IAdminEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });

        const { result, error } = await AccountAPI.edit(id, admin);
        if (result) {
            return dispatch(getList());
        }
        else {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
    }
}

export function logout() {

}


export function remove(id: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await AccountAPI.remove(id);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList());
    }
}