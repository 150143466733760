import React from 'react';
import Loading from '../Loading';
import { ERROR_MESSAGES } from '../../Shared/errors';
import Channel from '../../Entities/Channel';
import { IChannelEdit } from '../../Shared/interfaces';
// import Organization from '../../Entities/Organization';
// import organization from '../../Reducers/organization';

interface IState {
    name: string;
    address: string;
    // organizationId: number;
}

interface IProps {
    channel?: Channel;
    error: number | null;
    // organizationList: Organization[];
    onSubmit: (data: IChannelEdit) => void;
    isLoading: boolean;
}

export default class ItemChannel extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            name: '',
            address: ''
        }
    }

    componentDidUpdate(oldProps: IProps) {
        if (this.props.channel && !oldProps.channel) {
            const channel: Channel | undefined = this.props.channel;
            this.setState({
                name: channel.name,
                address: channel.address
            });
        }
    }

    handleChangeName = (event: any) => {
        const target = event.target;
        this.setState({
            name: target.value
        });
    }

    handleChangeAddress = (event: any) => {
        const target = event.target;
        this.setState({
            address: target.value
        });
    }

    // handleChangeOrganization = (event: any) => {
    //     const target = event.target;
    //     this.setState({
    //         organizationId: target.value
    //     });
    // }

    // renderCategoryOption = (organization: Organization) => {
    //     return (
    //         <option
    //             value={organization.getId()}
    //             selected={organization.getId() == this.state.organizationId}>
    //             {organization.name}
    //         </option>
    //     )
    // }

    onSubmit = () => {
        this.props.onSubmit(this.state);
    }

    render() {
        const { isLoading, error } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (<div className="row">
                <div className="col-md-12">
                    <div className="tile">
                        <div className="row">
                            <div className="col-lg-6">
                                {error ?
                                    <p>{ERROR_MESSAGES[error]}</p> : null}
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Название</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.name}
                                            id="exampleInputEmail1"
                                            type="text"
                                            onChange={this.handleChangeName}
                                            placeholder="Введите название" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Адресс</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.address}
                                            id="exampleInputEmail1"
                                            type="text"
                                            onChange={this.handleChangeAddress}
                                            placeholder="Введите адресс" />
                                    </div>
                                    {/* <div className="form-group">
                                        <label htmlFor="exampleSelect2">Организация</label>
                                        <select onChange={this.handleChangeOrganization} className="form-control" id="exampleSelect2" >
                                            {organizationList.map(this.renderCategoryOption)}
                                        </select>
                                    </div> */}
                                </form>
                                <div className="tile-footer">
                                    <button
                                        onClick={this.onSubmit}
                                        className="btn btn-primary"
                                        type="submit">Сохранить</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }
}