export enum NewsSourceStatus {
    ACTIVE = 1,
    INACTIVE = 2
}

export const NewsSourceStatusTitles = {
    [String(NewsSourceStatus.ACTIVE)]: 'Активный',
    [String(NewsSourceStatus.INACTIVE)]: 'Неактивный'
}

export enum REQUEST_SERVICE_TYPE {
    REPAIR = 1,
    TEST_DRIVE = 2,
    INSURANCE = 3,
    CREDIT = 4,
    TRADE_IN = 5,
    BUY_CAR = 6,
    TECH_SERVICE = 7
}

export const REQUEST_SERVICE_TITLE = {
    [String(REQUEST_SERVICE_TYPE.REPAIR)]: 'Ремонт',
    [String(REQUEST_SERVICE_TYPE.TEST_DRIVE)]: 'Тест-драйв',
    [String(REQUEST_SERVICE_TYPE.INSURANCE)]: 'Страховка',
    [String(REQUEST_SERVICE_TYPE.CREDIT)]: 'Кредит',
    [String(REQUEST_SERVICE_TYPE.TRADE_IN)]: 'Трейд-ин',
    [String(REQUEST_SERVICE_TYPE.BUY_CAR)]: 'Покупка',
    [String(REQUEST_SERVICE_TYPE.TECH_SERVICE)]: 'Технически осмотр',
}

export enum ROLES {
    ADMIN = 1,
    MANAGER = 2,
}

export enum SEX {
    MALE,
    FEMALE
}

export enum EVENT_TYPE {
	CLIENT_CLOSE = 1,
	EMPLOYEE_CLOSE = 2,
	EMPLOYEE_ACCEPT = 3,
	EMPLOYEE_CONNECT = 4,
	CLIENT_CONNECT = 5
}

export const EVENT_TYPE_TITLE = {
    [String(EVENT_TYPE.CLIENT_CLOSE)]: 'Закрыл сайт',
    [String(EVENT_TYPE.EMPLOYEE_CLOSE)]: 'Оператор завершил диалог',
    [String(EVENT_TYPE.EMPLOYEE_ACCEPT)]: 'Оператор принял диалог',
    [String(EVENT_TYPE.CLIENT_CONNECT)]: 'Клиент открыл сайт'
}


export enum NOTIFICATION_TYPE {
    SPECIAL_OFFER = 1,
    PERSONAL = 2,
    QUERY_STATUS = 3
}

export const NOTIFICATION_TYPE_TITILE = {
    [String(NOTIFICATION_TYPE.SPECIAL_OFFER)]: 'Специальное предложение',
    [String(NOTIFICATION_TYPE.PERSONAL)]: 'Персональное предложение',
    [String(NOTIFICATION_TYPE.QUERY_STATUS)]: 'Статус запроса'
}

export enum NOTIFICATION_STATUS {
    NOT_OPEN = 1,
    OPEN = 2
}

export enum EMPLOYEE_ROLES {
    MANAGER = 1,
    OPERATOR = 2
}