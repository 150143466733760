import React from 'react';

interface IProps {
    onSubmit: (email: string, password: string) => void,
    error: string | null;
}

interface IState {
    email: string;
    password: string;
}

export default class Login extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }
    }

    onChangeEmail = ({ target: { value } }: any) => {
        this.setState({
            email: value
        })
    }

    onChangePassword = ({ target: { value } }: any) => {
        this.setState({
            password: value
        })
    }

    onSubmit = () => {
        this.props.onSubmit(this.state.email, this.state.password);
    }

    render() {
        return (
            <>
                <section className="material-half-bg">
                    <div className="cover"></div>
                </section>
                <section className="login-content">
                    <div className="logo">
                        <h1>Vali</h1>
                    </div>
                    <div className="login-box">
                        <form className="login-form" >
                            <h3 className="login-head"><i className="fa fa-lg fa-fw fa-user"></i>Login</h3>
                            {this.props.error && this.props.error.length > 0 && <p>{this.props.error}</p>}
                            <div className="form-group">
                                <label className="control-label">EMAIL</label>
                                <input onChange={this.onChangeEmail} value={this.state.email} className="form-control" type="text" placeholder="Email" />
                            </div>
                            <div className="form-group">
                                <label className="control-label">PASSWORD</label>
                                <input onChange={this.onChangePassword} value={this.state.password} className="form-control" type="password" placeholder="Password" />
                            </div>
                            <div className="form-group btn-container">
                                <button onClick={this.onSubmit} className="btn btn-primary btn-block"><i className="fa fa-sign-in fa-lg fa-fw"></i>LOGIN</button>
                            </div>
                        </form>
                    </div>
                </section>
            </>
        )
    }
}

