
const store = localStorage;

export function setData(key: string, data: any) {
    if (!(typeof data === 'string' || data instanceof String)) {
        data = JSON.stringify(data);
    }
    return store.setItem(key, data);
}

export function getData(key: string) {
    const data = store.getItem(key);
    if(!data){
        return null;
    }
    if(typeof(data) == 'string'){
        return data;
    }

    return JSON.parse(data);
}

export function remove(key: string) {
    return store.removeItem(key);
}

export function clear() {
    return store.clear();
}

const TOKEN_KEY = '@token';

export function setToken(token: string) {
    setData(TOKEN_KEY, token);
}

export function getToken() {
    // return null;
    return getData(TOKEN_KEY);
}

export function removeToken() {
    return remove(TOKEN_KEY);
}