import React from 'react';
import Loading from '../Loading';
import { ERROR_MESSAGES } from '../../Shared/errors';
import Organization from '../../Entities/Organization';
import { IOrganizationEdit } from '../../Shared/interfaces';

interface IState {
    name: string;
}

interface IProps {
    organization?: Organization;
    error: number | null;
    onSubmit: (data: IOrganizationEdit) => void;
    isLoading: boolean;
}

export default class ItemOrganization extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            name: ''
        }
    }

    componentDidUpdate(oldProps: IProps) {
        if (this.props.organization && !oldProps.organization) {
            const organization: Organization | undefined = this.props.organization;
            this.setState({
                name: organization.name
            });
        }
    }

    handleChangeName = (event: any) => {
        const target = event.target;
        this.setState({
            name: target.value
        });
    }

    onSubmit = () => {
        this.props.onSubmit(this.state);
    }

    render() {
        const { isLoading, error } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (<div className="row">
                <div className="col-md-12">
                    <div className="tile">
                        <div className="row">
                            <div className="col-lg-6">
                                {error ?
                                    <p>{ERROR_MESSAGES[error]}</p> : null}
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Название</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.name}
                                            id="exampleInputEmail1"
                                            type="text"
                                            onChange={this.handleChangeName}
                                            placeholder="Введите название" />
                                    </div>
                                </form>
                                <div className="tile-footer">
                                    <button
                                        onClick={this.onSubmit}
                                        className="btn btn-primary"
                                        type="submit">Сохранить</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }
}