import React from 'react';
import Home from '../Components/Home';

interface IProps {

}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

export default class HomePage extends React.Component<IProps, IState> {
    render() {
        return <Home />;
    }
}

