import React, { Component } from 'react';
import ManagerAPI from '../Api/account';
import Login from './../Components/Login';
import Loading from '../Components/Loading';
import { getErrorMessage } from '../Shared/errors';

interface IProps {
    onSuccess: () => void
}

interface IState {
    isLoading: boolean;
    error: string | null;
}

export default class LoginPage extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: false,
            error: null
        }
    }

    onSubmit = async (email: string, password: string) => {
        this.setState({ isLoading: true });
        const { result, error } = await ManagerAPI.login(email, password);
        if (!result) {
            this.setState({
                error: getErrorMessage(error),
                isLoading: false
            });
        }
        else {
            this.props.onSuccess();
        }
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />
        }
        return <Login error={this.state.error} onSubmit={this.onSubmit} />;

    }
}

