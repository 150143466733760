import React from 'react';
import Loading from '../Loading';
import { EventLog } from '../../Entities/EventLog';
import Pagination from '../Pagination';


interface IProps {
	list: EventLog[];
	error: string;
	page: number;
	pages: number;
	isLoading: boolean;
	onLoadMore: (page: number) => void;
}

export default class EventLogList extends React.Component<IProps> {

	renderItem = (item: EventLog) => {
		return (
			<tr>
				<td>{item.getId()}</td>
				<td>{item.getEvent()}</td>
				<td>{item.clientId}</td>
				<td>{item.employeeId}</td>
				<td>
					{item.getDate()}
				</td>
			</tr>
		)
	}

	render() {
		const { isLoading, list } = this.props;
		if (isLoading) {
			return <Loading />;
		}
		else {
			return (
				<>

					<div className="app-title">
						<div>
							<h1><i className="fa fa-th-list"></i> Лог событий</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<div className="tile">
								<table className="table table-striped">
									<thead>
										<tr>
											<th>#</th>
											<th>Событие</th>
											<th>Клиент</th>
											<th>Оператор</th>
											<th>Дата</th>
										</tr>
									</thead>
									<tbody>
										{list.map(this.renderItem)}
									</tbody>
								</table>
								<Pagination
									pages={this.props.pages}
									current={this.props.page}
									onPage={this.props.onLoadMore}
								/>
							</div>
						</div>
					</div>
				</>
			)
		}
	}
}