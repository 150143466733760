import React from 'react';
import { ERROR_MESSAGES } from '../../Shared/errors';
import { ILinkEmployee } from '../../Shared/interfaces';
import { EMPLOYEE_ROLES } from '../../Shared/const';
import Department from '../../Entities/Department';
import department from '../../Reducers/department';
import { Link } from 'react-router-dom';

interface IState {
    departments: Set<number>;
    role: number;
}

interface IProps {
    departmentList: Department[];
    role?: EMPLOYEE_ROLES;
    departments?: number[];
    error: number | null;
    onSubmit: (data: ILinkEmployee) => void;
}

export default class ItemChangeEmployee extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            departments: new Set(this.props.departments),
            role: props.role ? props.role : EMPLOYEE_ROLES.MANAGER
        }
    }

    // componentDidUpdate(oldProps: IProps) {
    //     if (this.props.role
    //         && this.props.departments) {
    //         console.log('sdsd ', this.props);
    //         this.setState({
    //             role: this.props.role,
    //             departments: new Set(this.props.departments)
    //         })
    //     }

    // }


    handleChangeDepartment = (event: any) => {
        const value = Number(event.target.value);
        const departments = this.state.departments;
        departments.add(value);
        this.setState({
            departments
        });
    }

    removeSelectedDepartment = (id: number) => {
        const departments = this.state.departments;
        if (departments.has(id)) {
            departments.delete(id);
        }
        this.setState({
            departments
        });
    }

    handleChangeRole = (event: any) => {
        this.setState({
            role: Number(event.target.value)
        })
    }


    renderDepartmentOption = (department: Department) => {
        return (
            <option
                value={department.getId()}>
                {department.name}
            </option>
        )
    }

    renderSelectedDepartment = (department: Department) => {
        return (
            <tr>
                <td>{department.name}</td>
                <td>
                    <a href="#" onClick={() => this.removeSelectedDepartment(department.getId())}>Удалить</a>
                </td>
            </tr>
        )
    }

    onSubmit = () => {
        this.props.onSubmit({
            role: this.state.role,
            departments: Array.from(this.state.departments)
        });
    }

    render() {
        const { departmentList, error } = this.props;
        const departmentsForSelect = departmentList.filter((d: Department) => !this.state.departments.has(d.getId()));
        const selectedDepartments = departmentList.filter((d: Department) => this.state.departments.has(d.getId()));
        return (<div className="row">
            <div className="col-md-12">
                <div className="tile">
                    <div className="row">
                        <div className="col-lg-6">
                            {error ?
                                <p>{ERROR_MESSAGES[error]}</p> : null}
                            <form>
                                <div className="form-group">
                                    <label htmlFor="exampleSelect2">Роль</label>
                                    <select onChange={this.handleChangeRole} className="form-control" id="exampleSelect2" >
                                        <option
                                            selected={EMPLOYEE_ROLES.OPERATOR == this.state.role}
                                            value={EMPLOYEE_ROLES.OPERATOR}>Оператор</option>
                                        <option
                                            selected={EMPLOYEE_ROLES.MANAGER == this.state.role}
                                            value={EMPLOYEE_ROLES.MANAGER}>Менеджер</option>
                                    </select>
                                </div>
                                {this.state.role == EMPLOYEE_ROLES.OPERATOR ?
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="exampleSelect2">Доступные отделы</label>
                                            <select multiple onChange={this.handleChangeDepartment} className="form-control" id="exampleSelect2" >
                                                {departmentsForSelect.map(this.renderDepartmentOption)}
                                            </select>
                                        </div>
                                        <div>
                                            <div className="form-group">
                                                <label htmlFor="exampleSelect2">Выбранные отделы</label>
                                            </div>
                                        </div>
                                        <table className="table table-striped">
                                            <tbody>
                                                {selectedDepartments.map(this.renderSelectedDepartment)}
                                            </tbody>
                                        </table>
                                    </>
                                    : null}

                            </form>
                            <div className="tile-footer">
                                <button
                                    onClick={this.onSubmit}
                                    className="btn btn-primary"
                                    type="submit">Сохранить</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}