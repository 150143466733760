import React from 'react';
import Loading from '../Loading';
import { ERROR_MESSAGES } from '../../Shared/errors';
import { IChannelEdit, ILinkEmployee } from '../../Shared/interfaces';
import { EMPLOYEE_ROLES } from '../../Shared/const';
import Employee from '../../Entities/Employee';
import Department from '../../Entities/Department';

interface IState {
    employeeId: number;
    departments: Set<number>;
    role: number;
}

interface IProps {
    employeeList: Employee[];
    departmentList: Department[];
    employeeId?: number;
    role?: EMPLOYEE_ROLES;
    departments?: number[];
    error: number | null;
    onSubmit: (data: ILinkEmployee) => void;
}

export default class ItemChangeEmployee extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            employeeId: 0,
            departments: new Set([]),
            role: props.role ? props.role : EMPLOYEE_ROLES.MANAGER
        }
    }

    componentDidUpdate(oldProps: IProps) {
        if (this.props.role
            && this.props.departments
            && this.props.employeeId) {
            this.setState({
                role: this.props.role,
                employeeId: this.props.employeeId,
                departments: new Set(this.props.departments)
            })
        }

    }

    handleChangeEmployee = (event: any) => {
        const target = event.target;
        this.setState({
            employeeId: target.value
        });
    }

    handleChangeDepartment = (event: any) => {
        const value = Number(event.target.value);
        const departments = this.state.departments;
        if (departments.has(value)) {
            departments.delete(value);
        }
        else {
            departments.add(value);
        }

        this.setState({
            departments
        });
    }

    handleChangeRole = (event: any) => {
        this.setState({
            role: Number(event.target.value)
        })
    }

    renderEmployeeOption = (employee: Employee) => {
        return (
            <option
                value={employee.getId()}
                selected={employee.getId() == this.state.employeeId}>
                {employee.name}
            </option>
        )
    }

    renderDepartmentOption = (department: Department) => {
        return (
            <option
                value={department.getId()}
                selected={this.state.departments.has(department.getId())}>
                {department.name}
            </option>
        )
    }

    onSubmit = () => {
        this.props.onSubmit({
            employeeId: this.state.employeeId,
            role: this.state.role,
            departments: Array.from(this.state.departments)
        });
    }

    render() {
        const { departmentList, employeeList, error } = this.props;
        return (<div className="row">
            <div className="col-md-12">
                <div className="tile">
                    <div className="row">
                        <div className="col-lg-6">
                            {error ?
                                <p>{ERROR_MESSAGES[error]}</p> : null}
                            <form>
                                <div className="form-group">
                                    <label htmlFor="exampleSelect2">Оператор</label>
                                    <select onChange={this.handleChangeEmployee} className="form-control" id="exampleSelect2" >
                                        <option
                                            value={0}
                                            selected={this.props.employeeId == 0}>
                                            Не выбрано
                                        </option>
                                        {employeeList.map(this.renderEmployeeOption)}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleSelect2">Роль</label>
                                    <select onChange={this.handleChangeRole} className="form-control" id="exampleSelect2" >
                                        <option
                                            selected={EMPLOYEE_ROLES.OPERATOR == this.state.role}
                                            value={EMPLOYEE_ROLES.OPERATOR}>Оператор</option>
                                        <option
                                            selected={EMPLOYEE_ROLES.MANAGER == this.state.role}
                                            value={EMPLOYEE_ROLES.MANAGER}>Менеджер</option>
                                    </select>
                                </div>
                                {this.state.role == EMPLOYEE_ROLES.OPERATOR ?
                                    <div className="form-group">
                                        <label htmlFor="exampleSelect2">Отдел</label>
                                        <select multiple onChange={this.handleChangeDepartment} className="form-control" id="exampleSelect2" >
                                            {departmentList.map(this.renderDepartmentOption)}
                                        </select>
                                    </div> : null}
                            </form>
                            <div className="tile-footer">
                                <button
                                    onClick={this.onSubmit}
                                    className="btn btn-primary"
                                    type="submit">Сохранить</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}