import { SET_ERROR, SET_LIST, SET_ITEM, SET_LOADING } from '../Actions/employee';
import Employee from '../Entities/Employee';

interface IState {
    list: Employee[],
    item: Employee | null,
    error: string | null;
    isLoading: boolean;
};

const defaultState = {
    list: [],
    item: null,
    error: null,
    isLoading: false
};

export default function employee(state: IState = defaultState, action: any) {
    switch (action.type) {
        case SET_LIST:
            return {
                ...state,
                list: action.data,
                isLoading: false
            };

        case SET_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        case SET_ITEM:
            return {
                ...state,
                item: action.data,
                isLoading: false
            }
        default:
            return state;
    }
}
