import { SET_ERROR, SET_ERROR_LIST, SET_EVENT_LIST, SET_LOADING } from '../Actions/log';
import Channel from '../Entities/Channel';
import { ErrorLog } from '../Entities/ErrorLog';
import { EventLog } from '../Entities/EventLog';

interface IState {
	eventList: EventLog[],
	errorList: ErrorLog[],
	eventPages: number | null;
	eventPage: number | null;
	errorPages: number | null;
	errorPage: number | null;
	error: string | null;
	isLoading: boolean;
};

const defaultState = {
	eventList: [],
	errorList: [],
	eventPages: null,
	eventPage: null,
	errorPages: null,
	errorPage: null,
	error: null,
	isLoading: false
};

export default function log(state: IState = defaultState, action: any) {
	switch (action.type) {
		case SET_ERROR_LIST:
			return {
				...state,
				errorPage: action.page,
				errorPages: action.pages,
				errorList: action.list,
				isLoading: false
			};
		case SET_EVENT_LIST:
			return {
				...state,
				eventPage: action.page,
				eventPages: action.pages,
				eventList: action.list,
				isLoading: false
			};
		case SET_ERROR:
			return {
				...state,
				error: action.error,
				isLoading: false
			};
		case SET_LOADING:
			return {
				...state,
				isLoading: action.isLoading
			}
		default:
			return state;
	}
}
