import OrganizationAPI from '../Api/organization';
import { IOrganizationEdit } from '../Shared/interfaces';
import { mapOrganization } from '../Shared/mappers';

export const SET_ERROR = 'SET_ORGANIZATION_ERROR';
export const SET_LIST = 'SET_ORGANIZATION_LIST';
export const SET_LOADING = 'SET_ORGANIZATION_LOADING';

export function create(data: IOrganizationEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await OrganizationAPI.create(data);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList())
    }
}

export function edit(id: number, data: IOrganizationEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await OrganizationAPI.edit(id, data);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList())

    }
}

export function getList() {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await OrganizationAPI.getList();
        if (result) {
            return dispatch({
                type: SET_LIST,
                data: mapOrganization(data)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function remove(id: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await OrganizationAPI.remove(id);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList());
    }
}