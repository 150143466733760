import React from 'react';
import { connect } from 'react-redux';
import { create } from '../Actions/department';
import Routes from '../Shared/routes';
import AddDepartment from '../Components/Department/Item';
import { FILL_PARAMS_ERROR } from '../Shared/errors';
import { IDepartmentEdit } from '../Shared/interfaces';


interface IProps {
    isLoading: boolean;
    error: number | null;
    getList: () => void;
    create: (data: IDepartmentEdit) => void;
    history: any;
    match: any;
}

interface IState {
    error: number | null;
}

class AddDepartmentPage extends React.Component<IProps, IState> {
    channelId: number;

    constructor(props: IProps) {
        super(props);
        this.channelId = props.match.params.channelId;
    }

    state = {
        error: null
    }

    onSubmit = async (data: IDepartmentEdit) => {
        if (!data.name) {
            this.setState({
                error: FILL_PARAMS_ERROR
            })
        }
        else {
            await this.props.create({
                name: data.name,
                channelId: this.channelId
            });
            if (!this.props.error) {
                this.props.history.push(`${Routes.DEPARTMENT_LIST}${this.channelId}`)
            }
        }
    }

    render() {
        return (
            <AddDepartment
                error={this.props.error || this.state.error}
                isLoading={this.props.isLoading}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        create: (data: IDepartmentEdit) => dispatch(create(data))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.department.isLoading,
        error: state.department.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(AddDepartmentPage);
