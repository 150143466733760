import { SET_ERROR, SET_LIST, SET_LOADING } from '../Actions/department';
import Department from '../Entities/Department';

interface IState {
    list: Department[],
    error: string | null;
    isLoading: boolean;
};

const defaultState = {
    list: [],
    error: null,
    isLoading: false
};

export default function department(state: IState = defaultState, action: any) {
    switch (action.type) {
        case SET_LIST:
            return {
                ...state,
                list: action.data,
                isLoading: false
            };

        case SET_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        default:
            return state;
    }
}
