import LogApi from '../Api/log';
import { mapErrorLogs, mapEventLogs } from '../Shared/mappers';

export const SET_EVENT_LIST = 'SET_EVENT_LOG_LIST';
export const SET_ERROR_LIST = 'SET_ERROR_LOG_LIST';
export const SET_LOADING = 'SET_LOG_LOADING';
export const SET_ERROR = 'SET_LOG_ERROR';

export function getEventLog(page: number = 1) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await LogApi.getEventLog(page);
        if (result) {
            return dispatch({
                type: SET_EVENT_LIST,
                pages: data.pages,
                page: data.page,
                list: mapEventLogs(data.list)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function getErrorLog(page: number = 1) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await LogApi.getErrorLog(page);
        if (result) {
            return dispatch({
                type: SET_EVENT_LIST,
                pages: data.pages,
                page: data.page,
                list: mapErrorLogs(data.list)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}