import Organization from "../Entities/Organization";
import Admin from "../Entities/Admin";
import Channel from "../Entities/Channel";
import Department from "../Entities/Department";
import Employee from "../Entities/Employee";
import { EventLog } from "../Entities/EventLog";
import { ErrorLog } from "../Entities/ErrorLog";


export function mapChannel(data: any[]) {
    return data.map((item: any) => {
        return new Channel(item.id, item.name, item.address);
    });
}

export function mapDepartment(data: any[]) {
    return data.map((item: any) => {
        // const channel = new Channel(item.channel.id, item.channel.name, item.channel.address);
        return new Department(item.id, item.name);
    });
}

export function mapEmployee(data: any[]) {
    return data.map((item: any) => {
        return new Employee({
            id: item.id,
            name: item.name,
            email: item.email,
            isBlock: item.isBlock,
            isVerified: item.isVerified,
            channels: item.channels,
            channelsRoles: item.channels_roles,
            departments: item.departments
        });
    });
}

export function mapAdmin(data: any[]) {
    return data.map((item: any) => {
        return new Admin(item.id, item.name, item.email);
    });
}

export function mapOrganization(data: any[]) {
    return data.map((item: any) => {
        return new Organization(item.id, item.name);
    });
}

export function mapNotifications(data: any[]) {
    return data.map((item: any) => {
        return new Notification(item);
    });
}

export function mapEventLogs(data: any[]): EventLog[] {
    return data.map((item: any) => {
        return new EventLog(item.id, item.event, item.clientId, item.employeeId, item.createdAt);
    });
}

export function mapErrorLogs(data: any[]): ErrorLog[] {
    return data.map((item: any) => {
        return new ErrorLog(item.id, item.service, item.message, item.createdAt);
    });
}