import { combineReducers } from 'redux';
import admin from './admin';
import channel from './channel';
import employee from './employee';
import organization from './organization';
import log from './log';
import department from './department';

export default combineReducers({
    admin,
    organization,
    department,
    employee,
    log,
    channel
});
