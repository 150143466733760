import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk';
import rootReducer from './Reducers';

export default createStore(rootReducer, applyMiddleware(thunkMiddleware));
// import {connectRouter, routerMiddleware} from 'connected-react-router';

// import createHistory from 'history/createBrowserHistory';
// import rootReducer from './Reducers';
// import thunk from 'redux-thunk';

// export const history = createHistory();

// const middleware = [thunk, routerMiddleware(history)];
// const enhancers = [];
// if (process.env.NODE_ENV === 'development') {
//     const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

//     if (typeof devToolsExtension === 'function') {
//         enhancers.push(devToolsExtension());
//     }
// }

// const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
// export const store = createStore(connectRouter(history)(rootReducer), composedEnhancers);
// export default function configureStore() {
//     return store;
// }
