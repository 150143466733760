import { BaseEntity } from "./BaseEntity";
import moment from 'moment';
import { EVENT_TYPE_TITLE } from './../Shared/const';

export class EventLog extends BaseEntity {
	createdAt: Date;
	event: number;

	constructor(id: number, event: number, public clientId: number,
		public employeeId: number, createdAt: any) {
		super(id);
		this.createdAt = new Date(createdAt);
		this.event = event;
	}

	getDate(): string {
		return moment(this.createdAt).format('hh:mm DD:MM:YYYY');
	}

	getEvent(): string {
		return EVENT_TYPE_TITLE[this.event];
	}
}