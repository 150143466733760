import Api from '.';
import { IRequestResponse } from './index';
import { IAdminEdit } from '../Shared/interfaces';

class Admin extends Api {
    ENDPOINT = 'admin/'

    // async login(email: string, password: string) {
    //     const response = await this.post('auth/login', { email, password });
    //     console.log('respo ', response);
    //     if (response.result) {
    //         super.saveToken(response.data.accessToken);
    //     }
    //     return response;
    // }

    create(data: IAdminEdit) {
        return this.post(this.ENDPOINT, data);
    }

    edit(id: number, data: IAdminEdit) {
        return this.put(`${this.ENDPOINT}${id}`, data);
    }

    remove(id: number) {
        return this.delete(`${this.ENDPOINT}${id}`);
    }

    getCurrent(): Promise<IRequestResponse> {
        return this.get('auth');
    }

    getList(): Promise<IRequestResponse> {
        return this.get(this.ENDPOINT);
    }
}

const admin = new Admin();

export default admin;