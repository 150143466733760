export default {
    HOME: '/',
    ADMIN_CREATE: '/admin/create',
    ADMIN_LIST: '/admin/list',
    ADMIN_EDIT: '/admin/edit/',
    REQUEST_LIST: '/request/list',
    NEWS_CREATE: '/news/create',
    NEWS_EDIT: '/news/edit/',
    NEWS_LIST: '/news/list',
    EVENT_LOG: '/log/event',
    ERROR_LOG: '/log/error',
    ORGANIZATION_CREATE: '/organization/create',
    ORGANIZATION_EDIT: '/organization/edit/',
    ORGANIZATION_LIST: '/organization/list',
    CHANNEL_CREATE: '/channel/create',
    CHANNEL_EDIT: '/channel/edit/',
    CHANNEL_LIST: '/channel/list',
    DEPARTMENT_CREATE: '/department/create/',
    DEPARTMENT_EDIT: '/department/edit/',
    DEPARTMENT_LIST: '/department/list/',
    EMPLOYEE_CREATE: '/employee/create',
    EMPLOYEE_EDIT: '/employee/edit/',
    EMPLOYEE_LIST: '/employee/list',
    EMPLOYEE_LIST_BY_CHANNEL: '/employee/channel/',
    CHANNEL_LINK_EMPLOYEE: '/employee/link/channel/',
    EDIT_CHANNEL_LINK_EMPLOYEE: '/employee/edit/link/channel/',
    NOTIFICATION_LIST: '/notification/client/',
    NEWS_LIST_BY_STORE_OBJECT: `/news/storeObjects/`,
    CLIENT_ITEM: '/client/',
    CLIENT_LIST: '/client/list',
    NEWS_CATEGORY_CREATE: '/news/category/create',
    NEWS_CATEGORY_EDIT: '/news/category/edit/',
    NEWS_CATEGORY_LIST: '/news/category/list',
    NEWS_SOURCE_CREATE: '/news-source/create',
    NEWS_SOURCE_EDIT: '/news-source/edit/',
    NEWS_SOURCE_LIST: '/news-source/list',
    STORE_OBJECT_EDIT: '/storeObject/edit/',
    STORE_OBJECT_CREATE: '/storeObject/create',
    STORE_OBJECT_LIST: '/storeObject/list',
    REQUEST_LIST_BY_CLIENT: '/request/client/',
    PRIVACY: '/privacy'
}