import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './../Pages/Home';
import Routes from './../Shared/routes';
import AddAdmin from '../Pages/AddAdmin';
import EditAdmin from '../Pages/EditAdmin';
import EditOrganization from '../Pages/EditOrganization';
import OrganizationList from '../Pages/OrganizationList';
import AddChannel from '../Pages/AddChannel';
import EditChannel from '../Pages/EditChannel';
import ChannelList from '../Pages/ChannelList';
import AddEmployee from '../Pages/AddEmployee';
import EditEmployee from '../Pages/EditEmployee';
import EmployeeList from '../Pages/EmployeeList';
import LinkEmployee from '../Pages/LinkEmployee';
import EmployeeListByChannel from '../Pages/EmployeeListByChannel';
import AddDepartment from '../Pages/AddDepartment';
import EditDepartment from '../Pages/EditDepartment';
import DepartmentList from '../Pages/DepartmentList';
import EditLinkEmployee from '../Pages/EditLinkEmployee';
import ErrorLogList from '../Pages/ErrorLogList';
import EventLogList from '../Pages/EventLogList';

export default () => {
    return (
        <Switch>
            <Route path={Routes.ERROR_LOG} component={ErrorLogList} />
            <Route path={Routes.EVENT_LOG} component={EventLogList} />
            <Route path={Routes.ADMIN_CREATE} component={AddAdmin} />
            <Route path={`${Routes.ORGANIZATION_EDIT}:organizationId`} component={EditOrganization} />
            <Route path={Routes.ORGANIZATION_LIST} component={OrganizationList} />
            <Route path={Routes.CHANNEL_CREATE} component={AddChannel} />
            <Route path={`${Routes.CHANNEL_EDIT}:channelId`} component={EditChannel} />
            <Route path={Routes.CHANNEL_LIST} component={ChannelList} />
            <Route path={`${Routes.EDIT_CHANNEL_LINK_EMPLOYEE}:channelId/:employeeId`} component={EditLinkEmployee} />
            <Route path={Routes.EMPLOYEE_CREATE} component={AddEmployee} />
            <Route path={`${Routes.EMPLOYEE_EDIT}:employeeId`} component={EditEmployee} />
            <Route path={Routes.EMPLOYEE_LIST} component={EmployeeList} />
            <Route path={`${Routes.DEPARTMENT_CREATE}:channelId`} component={AddDepartment} />
            <Route path={`${Routes.DEPARTMENT_EDIT}:channelId/:departmentId`} component={EditDepartment} />
            <Route path={`${Routes.DEPARTMENT_LIST}:channelId`} component={DepartmentList} />
            <Route path={`${Routes.EMPLOYEE_LIST_BY_CHANNEL}:channelId`} component={EmployeeListByChannel} />
            <Route path={`${Routes.CHANNEL_LINK_EMPLOYEE}:channelId`} component={LinkEmployee} />
            <Route path={`${Routes.ADMIN_EDIT}:adminId`} component={EditAdmin} />

            <Route exact path={Routes.HOME} component={Home} />
        </Switch>
    );
};


