import React from 'react';

interface IProps {
    pages: number,
    current: number,
    onPage: (page: number) => void;
}

const MAX_PAGES = 10;

export default class Pagination extends React.Component<IProps> {

    renderPage = (page: number) => {
        let style = 'page-item ';
        if (page == this.props.current) {
            style += 'active';
        }
        return (
            <li className={style}><a onClick={() => this.props.onPage(page)} className="page-link" href="#">{page}</a></li>
        );
    }

    render() {

        let pages: Array<number> = [];
        let from = this.props.current - Math.ceil((MAX_PAGES - 1) / 2);
        let to = this.props.current + Math.floor((MAX_PAGES - 1) / 2);
        if (from <= 0) {
            to += Math.abs(from - 1);
            from = 1;
        }
        if (to > this.props.pages) {
            from -= to - this.props.pages;
            if (from <= 0) {
                from = 1;
            }
            to = this.props.pages;
        }
        let endPage = from;
        for (from; from <= to; from++) {
            pages.push(from);
        }
        if (pages.length <= 1) {
            return null;
        }
        return (
            <div className="bs-component">
                <div>
                    <ul className="pagination">
                        {this.props.current != 1 ?
                            <li
                                onClick={() => this.props.onPage(this.props.current - 1)}
                                className="page-item disabled"><a className="page-link" href="#">«</a></li>
                            : null}
                        {pages.map(this.renderPage)}
                        {endPage <= this.props.pages ?
                            <li
                                onClick={() => this.props.onPage(this.props.current + 1)}
                                className="page-item"><a className="page-link" href="#">»</a></li>
                            : null}
                    </ul>
                </div>
            </div>
        )
    }
}