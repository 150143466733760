import React from 'react';
import { connect } from 'react-redux';
import { create } from '../Actions/admin';
import AddAdminView from '../Components/Admin/Item';
import Routes from '../Shared/routes';
import { IAdminEdit } from '../Shared/interfaces';
import { isEmailValid } from '../Shared/utils';
import { EMAIL_IS_INVALID, FILL_PARAMS_ERROR } from '../Shared/errors';


interface IProps {
    isLoading: boolean;
    error: number;
    create: (data: IAdminEdit) => void;
    history: any;
}

interface IState {
    error: number | null;
}

class AddAdminPage extends React.Component<IProps, IState> {

    state = {
        error: null
    }

    onSubmit = async (data: IAdminEdit) => {
        if (!data.name || !data.email || !data.password) {
            this.setState({
                error: FILL_PARAMS_ERROR
            });
        }
        else if (!isEmailValid(data.email)) {
            this.setState({
                error: EMAIL_IS_INVALID
            });
        }
        else {
            await this.props.create(data);
            if (!this.props.error) {
                this.props.history.push(Routes.ADMIN_LIST)
            }
        }
    }

    render() {
        return (
            <AddAdminView
                error={this.props.error || this.state.error}
                isLoading={this.props.isLoading}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        create: (data: IAdminEdit) => dispatch(create(data)),
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.admin.isLoading,
        error: state.admin.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(AddAdminPage);
