import Api from '.';
import { IRequestResponse } from './index';
import { IOrganizationEdit } from '../Shared/interfaces';
import { EMPLOYEE_ROLES } from '../Shared/const';

class Organization extends Api {
    ENDPOINT = 'channels';

    create(data: IOrganizationEdit) {
        return this.post(this.ENDPOINT, data);
    }

    edit(id: number, data: IOrganizationEdit) {
        return this.put(`${this.ENDPOINT}/${id}`, data);
    }

    linkEmployee(channelId: number, employeeId: number, roleId: EMPLOYEE_ROLES) {
        return this.patch(`${this.ENDPOINT}/${channelId}/employee/link`, {
            employeeId,
            roleId
        });
    }

    unlinkEmployee(channelId: number, employeeId: number) {
        return this.patch(`${this.ENDPOINT}/${channelId}/employee/unlink`, {
            employeeId
        });
    }

    remove(id: number) {
        return this.delete(`${this.ENDPOINT}/${id}`);
    }

    getList(): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}`);
    }

}

const organization = new Organization();

export default organization;
