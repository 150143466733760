import React from 'react';
import { Router as BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Container from './../Components/Container';
import Router from '../Components/Router';

const history = createBrowserHistory();

export default class Main extends React.Component {
    render() {
        return (
            <Container>
                <Router />
            </Container>
        );

    }
}

