import React from 'react';
import { connect } from 'react-redux';
import { edit, getOne, getList as getEmployees } from '../Actions/employee';
import EditEmployee from '../Components/Employee/Item';
import Routes from '../Shared/routes';
import { EMAIL_IS_INVALID, FILL_PARAMS_ERROR } from '../Shared/errors';
import { IEmployeeEdit } from '../Shared/interfaces';
import Employee from '../Entities/Employee';
import { isEmailValid } from '../Shared/utils';
import Loading from '../Components/Loading';

interface IProps {
    isLoading: boolean;
    error: number;
    getEmployees: () => void;
    employee: Employee;
    getOne: (id: number) => void;
    edit: (id: number, data: IEmployeeEdit) => void;
    match: any;
    history: any;
}

interface IState {
    error: number | null;
}

class EditChannelPage extends React.Component<IProps, IState> {
    employee: Employee | undefined;

    state = {
        error: null
    }

    async componentDidMount() {
        const id = this.props.match.params.employeeId;
        await this.props.getOne(id);
        if (!this.props.employee) {
            this.props.history.push(Routes.EMPLOYEE_LIST);
        }
    }

    onSubmit = async (data: IEmployeeEdit) => {
        if (!data.name || !data.email) {
            this.setState({
                error: FILL_PARAMS_ERROR
            });
        }
        else if (!isEmailValid(data.email)) {
            this.setState({
                error: EMAIL_IS_INVALID
            });
        }
        else {
            await this.props.edit(this.props.employee.getId(),  data);
            if (!this.props.error) {
                this.props.history.push(Routes.EMPLOYEE_LIST);
            }
        }
    }

    render() {
        if (this.props.isLoading) {
            return <Loading />;
        }
        return (
            <EditEmployee
                error={this.props.error || this.props.error}
                isLoading={this.props.isLoading}
                employee={this.props.employee}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        edit: (id: number, data: IEmployeeEdit) => dispatch(edit(id, data)),
        getEmployees: () => dispatch(getEmployees()),
        getOne: (id: number) => dispatch(getOne(id))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.employee.isLoading,
        employee: state.employee.item,
        error: state.employee.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(EditChannelPage);
