import React from 'react';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import Routes from '../../Shared/routes';
import Employee from '../../Entities/Employee';


interface IProps {
    list: Employee[];
    error: string;
    channelId: number;
    remove: (organizationId: number) => void;
    add: () => void;
    isLoading: boolean;
}

export default class EmployeeList extends React.Component<IProps> {

    onAddSource = () => {
        this.props.add();
    }

    renderItem = (item: Employee) => {
        return (
            <tr>
                <td>{item.getId()}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>
                    <Link to={`${Routes.EDIT_CHANNEL_LINK_EMPLOYEE}${this.props.channelId}/${item.getId()}`}>Редактировать права</Link><br />
                    {/* <Link to={`${Routes.ORGANIZATION_EDIT}${item.getId()}`}>Редактировать</Link><br /> */}
                    <a href="#" onClick={() => this.props.remove(item.getId())}>Удалить</a>
                </td>
            </tr>
        )
    }

    render() {
        const { isLoading, list } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (
                <>

                    <div className="app-title">
                        <div>
                            <h1><i className="fa fa-th-list"></i> Операторы</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="tile">
                                <button
                                    onClick={this.onAddSource}
                                    className="btn btn-primary"
                                    type="submit">Добавить оператора к каналу</button>
                                <br />
                                <br />
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Название</th>
                                            <th>Почта</th>
                                            <th>Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map(this.renderItem)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}